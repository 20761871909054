<template>
  <validation-observer ref="form">
    <div class="row">
      <div class="col-12">
        <div class="text-h5 red--text">
          <span class="font-bold">Nota:</span>
          Los datos ingresados en este paso son de la empresa a quien se facturará
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <validation-provider vid="countryId" v-slot="{ errors }"
                                 name="País de ubicación de la empresa a quien se factura" rules="required">
              <v-select
                v-model="model.countryId"
                :error-messages="errors"
                :items="countries"
                @change="onChangeCompanyCountry"
                item-text="name"
                item-value="id"
                outlined
                dense
                label="País de ubicación de la empresa a quien se factura*"
              ></v-select>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <validation-provider
              vid="companyName"
              v-slot="{ errors }"
              name="Nombre de la empresa a quien se factura"
              rules="required|max:80"
            >
              <v-text-field
                v-model="model.companyName"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Nombre de la empresa a quien se factura*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <validation-provider
              vid="companyIdentification"
              v-slot="{ errors }"
              name="Número NIT para la factura"
              rules="required|max:80"
              @keypress="isLetterOrNumber($event)"
            >
              <v-text-field
                v-model="model.companyIdentification"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Número NIT para la factura*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <file-chooser
              ref="fileChooser"
              @input="onLoadRut"
              :options="{
                height: '220px',
                description: 'Haz clic aquí para cargar el ' + rutLabel + ' de la empresa. El archivo no debe ser superior a 30 días de expedido.',
                regex: /\.(jpg|jpeg|png|gif|pdf)$/,
                error: 'Solo se permiten imagenes o archivos PDF',
                fileSize: 2,
              }">
            </file-chooser>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="onDeleteImage"
                >
                  mdi-trash-can-outline
                </v-icon>
              </template>
              <span>Eliminar Imagen</span>
            </v-tooltip>
            <a
              :href="fileDirectory + model.rutFileUrl"
              target="_blank"
              v-if="roleId !== 3 && roleId !== 5 && model.rutFileUrl"
            >Descargar</a>
            <span class="red--text"> El archivo no debe ser superior a 30 días de expedido.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-0 pt-0">
        <div class="row">
          <div class="col-12 col-md-6 mt-0 pt-0">
            <validation-provider
              vid="currencyName"
              v-slot="{ errors }"
              name="Moneda de facturación"
            >
              <v-text-field
                v-model="currencyName"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Moneda de facturación*"
                required
                disabled
              ></v-text-field>
            </validation-provider>
          </div>

          <div class="col-12 col-md-6 mt-0 pt-0">
            <validation-provider
              vid="paymentMethodName"
              v-slot="{ errors }"
              rules="required"
              name="Forma de pago"
            >
              <v-select
                v-model="model.paymentMethodId"
                :error-messages="errors"
                :items="paymentMethods"
                item-text="name"
                item-value="id"
                outlined
                dense
                label="Forma de pago*"
              ></v-select>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <validation-provider
              vid="companyContactName"
              v-slot="{ errors }"
              name="Contacto para envío de factura"
              rules="required|max:255"
            >
              <v-text-field
                v-model="model.companyContactName"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Contacto para envío de factura*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
          <div class="col-12 col-md-6">
            <validation-provider vid="countryId" v-slot="{ errors }" name="País" rules="required">
              <v-select
                v-model="model.shippingCountryId"
                :error-messages="errors"
                :items="countries"
                @change="onChangeCountry"
                item-text="name"
                item-value="id"
                outlined
                dense
                label="País*"
              ></v-select>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <validation-provider
              vid="companyContactPositionDetail"
              v-slot="{ errors }"
              name="Cargo del contacto"
              rules="required|max:255"
            >
              <v-text-field
                v-model="model.companyContactPositionDetail"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Cargo del contacto*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
          <div class="col-12 col-md-6">
            <validation-provider vid="countryId" v-slot="{ errors }" name="Departamento/estado"
                                 rules="required">
              <v-select
                v-model="model.shippingDepartmentId"
                :error-messages="errors"
                :items="departments"
                @change="onChangeDepartment"
                item-text="name"
                item-value="id"
                outlined
                dense
                label="Departamento/estado*"
              ></v-select>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <validation-provider
              vid="companyContactEmail"
              v-slot="{ errors }"
              name="Correo autorizado para envío de factura electrónica"
              rules="required|max:255|email"
            >
              <v-text-field
                v-model="model.companyContactEmail"
                type="email"
                outlined
                dense
                :error-messages="errors"
                required
                background-color="yellow"
              >
                <template v-slot:label>
                  <strong>Correo autorizado para envío de factura electrónica*</strong>
                </template>
              </v-text-field>
            </validation-provider>
          </div>
          <div class="col-12 col-md-6">
            <validation-provider vid="shippingCityId" v-slot="{ errors }" name="Ciudad" rules="required">
              <v-select
                v-model="model.shippingCityId"
                :error-messages="errors"
                :items="cities"
                item-text="name"
                item-value="id"
                outlined
                dense
                label="Ciudad*"
              ></v-select>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <validation-provider
              vid="shippingAddress"
              v-slot="{ errors }"
              name="Dirección de entrega de factura"
              rules="required|max:255"
            >
              <v-text-field
                v-model="model.shippingAddress"
                type="email"
                outlined
                dense
                :error-messages="errors"
                label="Dirección de entrega de factura*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
          <div class="col-12 col-md-6">
            <validation-provider
              vid="dayTermToSendInvoice"
              v-slot="{ errors }"
              name="Día del mes límite para entregar la factura"
              rules="required|max:255"
            >
              <v-text-field
                v-model="model.dayTermToSendInvoice"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Día del mes límite para entregar la factura*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="d-flex flex-wrap">
              <div class="flex-grow-1">
                <validation-provider
                  vid="companyContactPhone"
                  v-slot="{ errors }"
                  name="Teléfono"
                  rules="required"
                >
                  <vue-tel-input-vuetify
                    label="Teléfono"
                    mode="international"
                    :error-messages="errors"
                    class="flex-wrap"
                    required
                    outlined
                    dense
                    v-model="model.companyContactPhone"
                    :preferred-countries="['CO', 'US']"
                  ></vue-tel-input-vuetify>
                </validation-provider>
              </div>
              <div class="flex-grow-1">
                <validation-provider
                  vid="companyContactPhoneExtension"
                  v-slot="{ errors }"
                  name="Extensión"
                  rules="integer"
                >
                  <v-text-field
                    v-model="model.companyContactPhoneExtension"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Extensión"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>
          </div>

          <div class="col-12 text-h5 red--text">
            <h3>Observaciones:</h3>
            <span>
            Por favor cuéntanos qué necesitamos saber o recibir antes de que enviemos la factura o al momento del envío de la factura; ej: orden de compra, orden de pago, No. De entrada, etc.
          </span>
            <h3>Respuesta con cantidad máxima de 200 caracteres</h3>
          </div>
          <div class="col-12 col-md-">
            <validation-provider
              vid="description"
              v-slot="{ errors }"
              name="Descripción"
              rules="required|max:200"
            >
              <v-textarea
                counter="200"
                outlined
                clearable
                v-model="model.description"
                type="text"
                dense
                :error-messages="errors"
                clear-icon="mdi-close-circle"
                label="Descripción"
                required
              ></v-textarea>
            </validation-provider>
          </div>
          <div class="col-12 red--text">
            <h3>Recuerda que el stand debe estar pago antes del evento.</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <v-btn color="primary" class="mr-2 text-none elevation-0" @click="saveStep6">
          <span v-if="!hasPermission('EDIT_BOOKING')">Siguiente</span>
          <span v-if="hasPermission('EDIT_BOOKING')">Guardar</span>
        </v-btn>
        <v-btn color="default" class="mr-2 text-none elevation-0" @click="$emit('nextStep',5)">
          Anterior
        </v-btn>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import FileChooser from '@/components/file-chooser/FileChooser'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import {
  BOOKINGS_URL,
  CITIES_URL,
  COUNTRIES_URL,
  DEPARTMENTS_URL,
  PAYMENT_METHODS_URL
} from '@/constants/ServicesConstants'
import eventHub from '@/eventhub'
import identificationMixin from '@/mixins/identificationMixin'
import sessionMixin from '@/mixins/sessionMixin'

export default {
  name: 'InvoiceData',
  props: {
    bookingId: {
      default: null,
      type: String
    }
  },
  async created () {
    eventHub.$on('step6', async () => {
      try {
        this.showLoader()
        await Promise.all([this.getCountries(), this.getPaymentMethods()])
        await this.getStep6()
        this.getRoleId()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    })
  },
  methods: {
    onLoadRut (file) {
      this.rutFile = file
    },
    onChangeCompanyCountry () {
      const country = this.countries.find((c) => {
        return c.id === this.model.countryId
      })
      if (country && country.name === 'Colombia') {
        this.currencyName = 'COP'
        this.model.currencyName = 'COP'
        this.model.currencyId = 1
      } else if (country && country.name === 'Perú' && this.peruFair) {
        this.currencyName = 'S/'
        this.model.currencyName = 'PEN'
        this.model.currencyId = 3
      } else {
        this.currencyName = 'USD'
        this.model.currencyName = 'USD'
        this.model.currencyId = 2
      }
    },
    async onChangeCountry () {
      try {
        this.showLoader()
        await this.getDepartments()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async onChangeDepartment () {
      try {
        this.showLoader()
        await this.getCities()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async getPaymentMethods () {
      const response = await this.get(PAYMENT_METHODS_URL, { params: { bookingId: this.bookingId } })
      this.paymentMethods = response.data
    },
    async getCountries () {
      this.departments = []
      this.cities = []
      const response = await this.get(COUNTRIES_URL)
      this.countries = response.data
    },
    async getDepartments (countryId) {
      this.cities = []
      const response = await this.getById(DEPARTMENTS_URL, this.model.shippingCountryId || countryId)
      this.departments = response.data
    },
    async getCities (departmentId) {
      const response = await this.getById(CITIES_URL, this.model.shippingDepartmentId || departmentId)
      this.cities = response.data
    },
    async getStep6 () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-6')
      const company = response.data
      if (company.shippingCountryId) {
        await this.getDepartments(company.shippingCountryId)
      }
      if (company.shippingDepartmentId) {
        await this.getCities(company.shippingDepartmentId)
      }
      this.model = response.data
      this.currencyName = this.model.currencyName === 'PEN' && this.peruFair ? 'S/' : this.model.currencyName
      if (!this.model.companyIdentification) {
        this.model.companyIdentification = this.model.previousCompanyIdentification
      }

      if (!this.model.companyName) {
        this.model.companyName = this.model.companyBusinessName
      }

      if (!this.model.countryId) {
        this.model.countryId = response.data.companyCountryId
      }

      if (this.model.rutFileUrl) {
        this.$nextTick(() => {
          this.$refs.fileChooser.setIsFile()
        })
      }
    },
    saveStep6 () {
      this.executeWithFormValidation(() => {
        if (this.rutFile || this.model.rutFileUrl) {
          const formData = this.getFormData()
          formData.append('rutFile', this.rutFile)
          this.create(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-6', formData).then(() => {
            this.$emit('nextStep', 7)
          })
        } else {
          this.$refs.fileChooser.setError('El ' + this.rutLabel + ' de la empresa es requerido')
        }
      })
    },
    onDeleteImage () {
      this.rutFile = null
      this.model.rutFileUrl = null
      this.$refs.fileChooser.deleteFile()
      this.logoFile = null
      this.model.logoUrl = null
      this.$refs.fileChooser.setImage(null)
    },
    getRoleId () {
      const user = this.getUser()
      this.roleId = user.roleId
    }
  },
  components: { FileChooser },
  mixins: [crudServiceMixin, loaderMixin, notificationMixin, formOperationsMixin, identificationMixin, sessionMixin],
  data () {
    return {
      currencyName: null,
      imagesUrl: process.env.VUE_APP_API_IMAGES_URL,
      roleId: null,
      companySizes: [],
      countries: [],
      departments: [],
      paymentMethods: [],
      cities: [],
      rutFile: null,
      fileDirectory: process.env.VUE_APP_API_IMAGES_URL,
      rutLabel: process.env.VUE_APP_RUT_LABEL,
      model: {
        countryId: null,
        companyBusinessName: null,
        companyName: null,
        companyIdentification: null,
        companyContactId: null,
        companyContactName: null,
        companyContactPositionDetail: null,
        companyContactEmail: null,
        currencyId: null,
        currencyName: null,
        shippingAddress: null,
        dayTermToSendInvoice: null,
        companyContactPhone: null,
        companyContactPhoneExtension: null,
        paymentMethodName: null,
        previousCompanyIdentification: null,
        shippingCountryId: null,
        shippingDepartmentId: null,
        shippingCityId: null,
        rutFileUrl: null,
        description: null
      },
      peruFair: process.env.VUE_APP_PERU_FAIR === '1'
    }
  }
}
</script>
